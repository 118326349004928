import * as React from 'react';

import { ORDER_TYPE, dataTransactionTab } from 'config';
import { useStateTransaction } from 'state/state-transaction';
import { useTransaction } from 'hooks';

import { NoData, WrapperTab } from "components";
import { Select } from 'antd';
import { TransactionType } from 'type';
import { IconCalendar } from 'icons';
import { BuySharesDesktop, BuySharesMobile, SellSharesDesktop, SellSharesMobile } from './components';

const { Option } = Select;


export const Transaction = () => {
    useStateTransaction();

    const { storeTransaction } = useTransaction();
    const [selectStocksCode, setSelectStocksCode] = React.useState("ALL");
    const [activeTab, setActiveTab] = React.useState<number>(1); // 0 => <BuySharesMobile/> , 1 => <SellSharesM

    const filterData = React.useMemo(() => {
        return selectStocksCode === "ALL" ? storeTransaction.data : storeTransaction.data.filter((item: TransactionType) => item.stocks_code === selectStocksCode);
    }, [selectStocksCode, storeTransaction.data]);

    const dataShellShares = React.useMemo(() => {
        const filteredData = filterData.filter((item: TransactionType) => item.order_type === ORDER_TYPE.SELL);
        return filteredData.toSorted((a, b) => a.unit_price - b.unit_price);
    }, [filterData]);

    const dataBuyShares = React.useMemo(() => {
        const filteredData = filterData.filter((item: TransactionType) => item.order_type === ORDER_TYPE.BUY);
        return filteredData.toSorted((a, b) => b.unit_price - a.unit_price);
    }, [filterData]);

    const dataOption = React.useMemo(() => {
        return storeTransaction?.data?.reduce((acc, item) => {
            const stocksCode = acc.find(i => i?.value === item.stocks_code);
            if (!stocksCode) {
                acc.push({
                    value: item.stocks_code,
                    label: item.stocks_code
                })
            }
            return acc;
        }, [{ value: "ALL", label: "Tất cả" }]);
    }, [storeTransaction?.data]);

    const handleChangeSelect = React.useCallback((stocksCode) => {
        setSelectStocksCode(stocksCode)
    }, []);

    return (
        <>
            <div className="flex justify-between items-center sm:flex-row flex-col gap-4">
                <h6 className="sm:text-4xl sm:w-auto w-full text-left text-lg text-[#23262F] font-bold">Giao dịch</h6>
                <div className="sm:w-[300px] w-full rounded-lg" style={{ border: "2px solid #355DFF" }}>
                    <Select
                        bordered={false}
                        placeholder="Mã cổ phiếu!"
                        optionLabelProp="label"
                        className="w-full"
                        size="large"
                        defaultValue="ALL"
                        notFoundContent={<NoData />}
                        onChange={handleChangeSelect}
                    >
                        {
                            dataOption.map((item) => (
                                <Option
                                    value={item.value}
                                    label={
                                        <div className="flex items-center gap-2">
                                            <IconCalendar />
                                            <p className="text-[#11142D] md:text-sm text-xs font-bold">{item.label}</p>
                                        </div>
                                    }
                                >
                                    <p className="text-[#11142D] md:text-sm text-xs">{item.label}</p>
                                </Option>
                            ))
                        }
                    </Select>
                </div>
            </div>
            <div className="xl:grid hidden grid-cols-2 w-full mt-8 gap-8">
                <BuySharesDesktop data={dataShellShares} loading={storeTransaction.loading} />
                <SellSharesDesktop data={dataBuyShares} loading={storeTransaction.loading} />
            </div>
            <div className="xl:hidden block">
                <div className="my-4">
                    <WrapperTab data={dataTransactionTab} activeTab={activeTab} setActiveTab={setActiveTab} className="w-full xl:w-auto" />
                </div>
                <div>
                    {activeTab === 0 && <BuySharesMobile data={dataShellShares} loading={storeTransaction.loading} />}
                    {activeTab === 1 && <SellSharesMobile data={dataBuyShares} loading={storeTransaction.loading} />}
                </div>
            </div>
        </>
    )
};
