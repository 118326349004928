import clsx from "clsx";

import { Button } from "@pancakeswap/uikit";
import ConnectWalletButton from "components/ConnectWalletButton";
import { useActiveWeb3React } from "hooks";
import { useModal } from "hooks/use-modal";
import { IconCreateSell } from "icons";

export const ButtonSellOrder = ({ className }: { className: string }) => {
    const { toggleModal, ModalTypeEnum } = useModal();
    const { account } = useActiveWeb3React();

    return (
        <>
            {
                account ? <Button
                    className={clsx("px-0", className)}
                    width="100%"
                    variant="success"
                    onClick={() => toggleModal({
                        type: ModalTypeEnum.MODAL_CREATE_SELL_ORDER,
                        title: "BÁN CỔ PHIẾU"
                    })}
                    endIcon={<IconCreateSell />}
                >
                    Tạo lệnh bán
                </Button>
                    : <ConnectWalletButton style={{ backgroundColor: "#11DC2E" }} />
            }
        </>
    )
}
