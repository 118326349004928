import { Table, Tooltip } from "antd";

import { PAYMENT, formatNumberPayment } from "config";
import { useInfoAsset } from "hooks";
import { InfoAssetType } from "type";

import { TitleTableDesktop, ValueTableDesktop, NoData } from "components";
import { IconWarningRed } from "icons";

export const TableDesktop = ({ data }: { data: InfoAssetType[] }) => {
    const { storeAsset } = useInfoAsset();
    const columns = [
        {
            title: <TitleTableDesktop title={`Mã cổ phiếu (${data.length})`} />,
            dataIndex: "stocks_code",
            key: "stocks_code",
            render: (_, record: InfoAssetType) => {
                return (
                    <div className="relative flex items-center">
                        {record.paused &&
                            <div className="absolute left-[-30px] top-0 cursor-pointer">
                                <Tooltip
                                    title={<p
                                        className="text-[#EF1616] text-xs font-medium"
                                    >
                                        Mã cổ phiếu đang tạm ngừng giao dịch
                                    </p>
                                    }
                                    placement="topLeft"
                                    color="#FDEDED"
                                >
                                    <IconWarningRed />
                                </Tooltip>
                            </div>
                        }
                        <ValueTableDesktop color={record.paused && "text-[#EF1616]"} className="x-12" value={record.stocks_code} />
                    </div>
                )
            }
        },
        {
            title: <TitleTableDesktop className="px-24" classNameWrapper="justify-end items-end" title={`Mệnh giá (${PAYMENT})`} />,
            dataIndex: "price",
            key: "price",
            render: (_, record: InfoAssetType) => {
                return (
                    <ValueTableDesktop classNameWrapper="justify-end items-end" color={record.paused && "text-[#EF1616]"} className="px-24" value={formatNumberPayment(record.price)} />
                )
            }
        },
        {
            title: <TitleTableDesktop className="px-24" classNameWrapper="justify-end items-end" title="Số lượng" />,
            dataIndex: "quantity",
            key: "quantity",
            render: (_, record: InfoAssetType) => {
                return (
                    <ValueTableDesktop classNameWrapper="justify-end items-end" color={record.paused && "text-[#EF1616]"} className="px-24" value={formatNumberPayment(record.quantity)} />
                )
            }
        },
        {
            title: <TitleTableDesktop className="px-12" classNameWrapper="justify-end items-end" title={`Tổng giá trị (${PAYMENT})`} />,
            dataIndex: "total_value",
            key: "total_value",
            render: (_, record: InfoAssetType) => {
                return (
                    <ValueTableDesktop classNameWrapper="justify-end items-end" color={record.paused && "text-[#EF1616]"} className="px-12" value={formatNumberPayment(record.total_value)} />
                )
            }
        }
    ];

    return (
        <Table
            loading={storeAsset.loading}
            locale={{
                emptyText: <NoData />,
            }}
            dataSource={data}
            columns={columns}
            pagination={{
                position: ["bottomCenter"],
                defaultPageSize: 5
            }}
        />
    )
};
