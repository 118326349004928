import * as React from 'react';

import { dataAssetsTab } from 'config';

import { TitleSection, WrapperTab } from 'components';
import { useActiveWeb3React } from 'hooks';
import { Asset, History, OrderBook } from './components';

export const InformationAssets = () => {
    const { account } = useActiveWeb3React();

    const [activeTab, setActiveTab] = React.useState<number>(1); // 0 => OrderBook, 1 => Asset, 2 => History
    const ListTab = [OrderBook, Asset, History];
    const CheckInfoTab = ListTab[activeTab];

    React.useLayoutEffect(() => {
        if (account) {
            setActiveTab(1);
        }
    }, [account])

    return (
        <div className='min-h-screen mt-[30px]'>
            <div className="flex sm:flex-row flex-col justify-between items-center gap-4">
                <TitleSection title="Thông tin tài sản" />
                <WrapperTab data={dataAssetsTab} activeTab={activeTab} setActiveTab={setActiveTab} className="w-full sm:w-auto" />
            </div>
            <div className="mt-[32px]">
                <CheckInfoTab />
            </div>
        </div>
    )
};

