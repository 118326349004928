import { Table, Tooltip } from "antd";
import clsx from "clsx";

import { NoData, TitleTableDesktop, ValueTableDesktop } from "components";
import { PAYMENT, checkOrderColor, checkStatusColor, formatDate, formatNumberPayment, handleWallet } from "config";
import { useActiveWeb3React } from "hooks";
import { TbExternalLink } from "react-icons/tb";
import { InfoHistoryType } from "type";
import { getBlockExploreLink } from "utils";


export const TableDesktop = ({ data, loading }: { data: InfoHistoryType[], loading: boolean }) => {
    const { chainId, } = useActiveWeb3React();

    const columns: any = [
        {
            title: <TitleTableDesktop title="Loại lệnh" />,
            dataIndex: "order_code",
            key: "order_code",
            render: (_, record) => <ValueTableDesktop value={record.order_code} color={`text-[${checkOrderColor(record.order_type)}]`} />
        },
        {
            title: <TitleTableDesktop title="Mã giao dịch" />,
            dataIndex: "transaction",
            key: "transaction",
            render: (_, record: InfoHistoryType) => {
                return (
                    record.transaction &&
                    <a
                        href={getBlockExploreLink(record.transaction, 'transaction', chainId)}
                        target="_blank" rel="noopener noreferrer"
                        className="truncate flex gap-1 items-center hover:text-[#355DFF]"
                    >
                        <ValueTableDesktop
                            value={handleWallet(record.transaction)}
                            classNameWrapper="justify-center"
                            color="hover:text-[#355DFF] text-[#5D5D5B]"
                        />
                        <TbExternalLink className="text-xl hover:text-[#355DFF]" />
                    </a>
                )
            }
        },
        {
            title: <TitleTableDesktop title="Ngày giao dịch" />,
            dataIndex: "times_tamp",
            key: "times_tamp",
            render: (text) => <ValueTableDesktop value={formatDate(text)} />
        },
        {
            title: <TitleTableDesktop title="Mã cổ phiếu" classNameWrapper="flex justify-end" />,
            dataIndex: "stocks_code",
            key: "stocks_code",
            render: (text) => <ValueTableDesktop value={text} classNameWrapper="flex font-bold justify-end" />
        },
        {
            title: <TitleTableDesktop title="Số lượng đặt" classNameWrapper="flex justify-end" />,
            dataIndex: "original_quantity",
            key: "original_quantity",
            render: (text) => <ValueTableDesktop classNameWrapper="flex py-2 justify-end" value={formatNumberPayment(text)} />
        },
        {
            title: <TitleTableDesktop title="Số lượng khớp" classNameWrapper="flex justify-end" />,
            dataIndex: "amount",
            key: "amount",
            render: (_, record: InfoHistoryType) => <ValueTableDesktop value={formatNumberPayment(record.amount)} classNameWrapper="flex justify-end" />
        },
        {
            title: <TitleTableDesktop title="Đơn giá" classNameWrapper="justify-end" />,
            dataIndex: "unit_price",
            key: "unit_price",
            render: (text) => {
                return (
                    text?.toString()?.length > 9
                        ? <Tooltip title={formatNumberPayment(text)}>
                            <p className="text-[#5D5D5B] text-sm font-bold flex justify-end">
                                {handleWallet(text.toString(), 3)}
                            </p>
                        </Tooltip>
                        : <ValueTableDesktop value={formatNumberPayment(text)} classNameWrapper="justify-end" />
                )
            }
        },
        {
            title: <TitleTableDesktop title="TGT" titleTooltip={`Tổng giá trị (${PAYMENT})`} classNameWrapper="justify-end" />,
            dataIndex: "total_value",
            key: "total_value",
            render: (text) => {
                return (
                    text?.toString()?.length > 9
                        ? <Tooltip title={formatNumberPayment(text)}>
                            <p className="text-[#5D5D5B] text-sm font-bold flex justify-end">
                                {handleWallet(text.toString(), 3)}
                            </p>
                        </Tooltip>
                        : <ValueTableDesktop value={formatNumberPayment(text)} classNameWrapper="justify-end" />
                )
            }
        },
        {
            title: <TitleTableDesktop classNameWrapper="flex justify-end" title="Trạng thái" />,
            dataIndex: "status_code",
            key: "status_code",
            render: (_, record) => <div className="flex justify-end">
                <p className={clsx("font-bold text-sm", `text-[${checkStatusColor(record.status)}]`)}>{record.status_code}</p>
            </div>
        }
    ];

    return (
        <Table
            loading={loading}
            locale={{
                emptyText: <NoData />,
            }}
            dataSource={data}
            columns={columns}
            pagination={false}
        />
    )
};


