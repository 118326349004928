import PageFullWidth from "components/Layout/PageFullWidth";
import { InformationAssets, Transaction } from "./components";

const Home = () => {
    return (
        <PageFullWidth>
            <Transaction />
            <InformationAssets />
        </PageFullWidth>
    )
}

export default Home
