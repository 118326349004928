import { Input } from "antd";

import { Button, EnterIcon } from "@pancakeswap/uikit";
import { PAYMENT, formatNumberPayment } from 'config';
import { useAccount } from "hooks";
import { useModal } from "hooks/use-modal";
import { EquivalentPayment } from "components";

interface HeaderType {
    onSearch: (e) => void
}

export const Header = ({ onSearch }: HeaderType) => {
    const { toggleModal, ModalTypeEnum } = useModal();
    const { storeAccount } = useAccount();
    const { balance } = storeAccount.data;

    return (
        <div className="flex lg:lex-col flex-col-reverse justify-start rounded-[20px] bg-[#F8F8F8] lg:p-[32px] p-6 gap-4">
            <div className="flex flex-col">
                <p className="text-[#353945] font-bold sm:text-sm text-xs md:pb-2 pb-0">Số dư khả dụng</p>
                <div className="flex flex-row gap-2 items-center">
                    <h6 className="text-[#23262F] font-bold sm:text-2xl text-base">{formatNumberPayment(balance)}</h6>
                    <p className="text-xs bg-[#58BD7D] rounded-[4px] p-1 font-semibold">{PAYMENT}</p>
                </div>
                <div className="mt-3">
                    <EquivalentPayment value={balance} />
                </div>
            </div>
            <div className="flex flex-row justify-between items-center">
                <h6 className="text-[#23262F] font-bold text-2xl lg:block hidden w-full">Tổng quan</h6>
                <div className="flex lg:flex-row flex-col justify-end lg:gap-2 gap-4 w-full">
                    <div className="flex flex-row justify-end gap-2 w-full">
                        <div className="lg:w-80 w-full bg-white rounded-md">
                            <Input
                                onChange={e => {
                                    onSearch(e.target.value);
                                }}
                                allowClear
                                size="large"
                                className="lg:py-3 py-2"
                                bordered={false}
                                placeholder="Tìm kiếm theo mã cổ phiếu"
                            />
                        </div>
                    </div>
                    <Button
                        className="lg:max-w-[200px] w-full px-0"
                        startIcon={<EnterIcon />}
                        variant="primary"
                        onClick={() => toggleModal({
                            type: ModalTypeEnum.MODAL_TRANSFER,
                            title: "CHUYỂN NHƯỢNG",
                            data: {
                                balance
                            }
                        })}
                    >Chuyển nhượng</Button>
                </div>
            </div>
        </div>
    )
};
