import * as React from 'react';
import { Pagination } from 'antd';
import dayjs from 'dayjs';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { STATUS_TYPE } from 'config';
import { useActiveWeb3React, useInfoHistory } from 'hooks';
import { useSlowRefreshEffect } from 'hooks/useRefreshEffect';
import { HeaderFilter } from './header-filter';
import { TableDesktop } from './table-desktop';
import { TableMobile } from './table-mobile';

const limit = 15;
const fromDate = Math.floor(dayjs().startOf('day').valueOf() / 1000);
const toDate = Math.floor(dayjs().endOf("day").valueOf() / 1000);

export const History = () => {
    const { chainId, account } = useActiveWeb3React();
    const { handleGetApiHistory, handleDisconnectWalletHistory, storeHistory } = useInfoHistory();

    useSlowRefreshEffect(() => {
        if (account) {
            handleGetApiHistory({
                chainId,
                walletAddress: account.toLowerCase(),
                limit,
                fromDate,
                toDate,
                isAprrover: false,
                client: true,
                status: [STATUS_TYPE.COMPLETED, STATUS_TYPE.REJECTED, STATUS_TYPE.CANCELLED, STATUS_TYPE.CREATED],
            });
        } else {
            handleDisconnectWalletHistory([])
        }
    }, [account, chainId, handleDisconnectWalletHistory, handleGetApiHistory]);


    return (
        <LazyLoadComponent>
            <HeaderFilter />
            <div className="mt-6 xl:block hidden">
                <TableDesktop data={storeHistory.data} loading={account ? storeHistory.loading : false} />
            </div>
            <div className="mt-6 xl:hidden block">
                <TableMobile data={storeHistory.data} loading={account ? storeHistory.loading : false} />
            </div>
            {
                storeHistory.total > 0 &&
                <div className="my-6">
                    <Pagination
                        defaultPageSize={limit}
                        onChange={page => {
                            const paramsUrl = {
                                ...storeHistory.paramsUrl,
                                limit: storeHistory.paramsUrl.limit,
                                skip: (page - 1) * limit
                            }
                            handleGetApiHistory(paramsUrl);
                        }}
                        defaultCurrent={1}
                        total={storeHistory.total}
                    />
                </div>
            }
        </LazyLoadComponent>
    )
}
