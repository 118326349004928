import { Button } from '@pancakeswap/uikit';
import clsx from 'clsx';
import ConnectWalletButton from 'components/ConnectWalletButton';
import { useActiveWeb3React } from 'hooks';
import { useModal } from 'hooks/use-modal';
import { IconCreateBuy } from 'icons';

export const ButtonBuyOrder = ({ className }: { className: string }) => {
    const { toggleModal, ModalTypeEnum } = useModal();
    const { account } = useActiveWeb3React();

    return (
        <>
            {
                account
                    ? <Button
                        className={clsx("px-0", className)}
                        width="100%"
                        variant="red"
                        onClick={() => toggleModal({
                            type: ModalTypeEnum.MODAL_CREATE_BUY_ORDER,
                            title: "MUA CỔ PHIẾU"
                        })}
                        endIcon={<IconCreateBuy />}
                    >
                        Tạo lệnh mua
                    </Button>
                    : <ConnectWalletButton style={{ backgroundColor: "#FF6838" }} />
            }
        </>
    )
}
