import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
    return (
        <Svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path opacity="0.396272" d="M15 16H6V14H14V2H4V8H2V1C2 0.448 2.448 0 3 0H15C15.552 0 16 0.448 16 1V15C16 15.552 15.552 16 15 16Z" fill="white" />
            <path d="M11.9999 4H5.99994L8.29294 6.293L0.585938 14L1.99994 15.414L9.70694 7.707L11.9999 10V4Z" fill="white" />
        </Svg>
    );
};

export default Icon;
