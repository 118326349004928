import { Tooltip } from 'antd';
import clsx from 'clsx';

import { LoadingTransactionMobile, NoData, TitleTableMobile, ValueTableMobile, WrapperCollapse } from 'components';
import { ORDER_TYPE, checkOrderColor, checkStatusColor, formatDate, formatNumberPayment, handleWallet } from 'config';
import { useActiveWeb3React } from 'hooks';
import { TbExternalLink } from "react-icons/tb";
import { InfoHistoryType } from 'type';
import { getBlockExploreLink } from 'utils';

export const TableMobile = ({ data, loading }: { data: InfoHistoryType[], loading: boolean }) => {
    const { chainId } = useActiveWeb3React();
    if (loading) return <LoadingTransactionMobile />
    if (!data.length) return <NoData />

    return (
        <div>
            <div className="lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 grid gap-4">
                {data.map((item: InfoHistoryType) => (
                    <div
                        className="rounded-lg pb-4"
                        style={{ borderBottom: "1px solid #E1E8E5" }}
                        key={item.transaction}
                    >
                        <div className="flex flex-row justify-between pb-3">
                            <div className="flex flex-col gap-y-[14px] w-1/3">
                                <TitleTableMobile title="Loại lệnh" />
                                <ValueTableMobile value={item.order_code} color={`text-[${checkOrderColor(item.order_type)}]`} />
                            </div>
                            <div className="flex flex-col gap-y-[14px] w-1/6">
                                <TitleTableMobile title="Mã" classNameWrapper="justify-start" />
                                <ValueTableMobile
                                    value={item?.stocks_code?.length > 10
                                        ? <Tooltip title={item.stocks_code}>{handleWallet(item.stocks_code.toString(), 2)}</Tooltip>
                                        : item.stocks_code}
                                    classNameWrapper="justify-start" />
                            </div>
                            <div className="flex flex-col gap-y-[14px] w-1/6">
                                <TitleTableMobile title="TGT" titleTooltip="Tổng giá trị" classNameWrapper="justify-start" />
                                <ValueTableMobile classNameWrapper="justify-start" value={formatNumberPayment(item.total_value)} className="text-left" />
                            </div>
                            <div className="flex flex-col gap-y-[14px] w-1/3">
                                <TitleTableMobile title="Trạng thái" classNameWrapper="justify-end" />
                                <p className={clsx("font-bold text-sm text-right", `text-[${checkStatusColor(item.status)}]`)}>{item.status_code}</p>
                            </div>
                        </div>
                        <WrapperCollapse>
                            <div className="flex flex-col gap-3 mt-3">
                                <div className="flex justify-between">
                                    <TitleTableMobile title="Mã giao dịch" />
                                    <a
                                        href={getBlockExploreLink(item.transaction, 'transaction', chainId)}
                                        target="_blank" rel="noopener noreferrer"
                                        className="truncate flex gap-1 items-center hover:text-[#355DFF]"
                                    >
                                        <ValueTableMobile
                                            value={handleWallet(item.transaction)}
                                            classNameWrapper="justify-center"
                                            className="hover:text-[#355DFF]"
                                        />
                                        <TbExternalLink className="text-xl hover:text-[#355DFF]" />
                                    </a>
                                </div>
                                <div className="flex justify-between">
                                    <TitleTableMobile title="Ngày giao dịch" />
                                    <ValueTableMobile value={formatDate(item.times_tamp)} className="font-normal" />
                                </div>
                                <div className="flex justify-between">
                                    <TitleTableMobile title="Số lượng đặt" />
                                    <ValueTableMobile value={formatNumberPayment(item.original_quantity)} className="font-normal" />
                                </div>
                                <div className="flex justify-between">
                                    <TitleTableMobile title="Số lượng khớp" />
                                    <ValueTableMobile value={formatNumberPayment(item.amount)} className="font-normal" />
                                </div>
                                <div className="flex justify-between">
                                    <TitleTableMobile title="Đơn giá" />
                                    <ValueTableMobile value={formatNumberPayment(item.unit_price)} className="font-normal" />
                                </div>
                            </div>
                        </WrapperCollapse>
                    </div>
                ))}
            </div>
        </div>
    )
};
