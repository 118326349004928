import { DatePicker, Input, Select, Space } from 'antd';
import dayjs from "dayjs";
import * as React from 'react';

import { CalendarIcon, SearchIcon, } from "@pancakeswap/uikit";
import { NoData, WrapperSelect } from "components";
import { DefaultDataOptionStocksCodeType, ORDER_TYPE, defaultDataOptionStocksCode } from 'config';
import { renderMarketplaceAddress, useActiveWeb3React, useInfoHistory } from 'hooks';
import { IconStatusUp, IconTrade, } from 'icons';
import { getOrderAsListStockCode } from 'state/state-info-order/fetch-data';
import { GetOrderAsListType } from 'type';

interface DataOptionType {
    value: string;
    label: string;
}

export const HeaderFilter = () => {
    const { handleGetApiHistory, storeHistory } = useInfoHistory();
    const { chainId, account } = useActiveWeb3React();
    const marketplaceAddress = renderMarketplaceAddress(chainId);

    const [dataOptionStocks, setDataOptionStocks] = React.useState<DataOptionType[]>([]);
    const [formDate, setFormDate] = React.useState<number>(dayjs().valueOf());

    React.useLayoutEffect(() => {
        (async () => {
            const result = await getOrderAsListStockCode(marketplaceAddress, chainId, account);
            const nftOrder = result.reduce((acc, item: GetOrderAsListType) => {
                acc.push({
                    value: item.nft_address,
                    label: item.stocks_code,
                })
                return acc;
            }, []);
            setDataOptionStocks([{ value: "ALL", label: "Tất cả" }, ...nftOrder])
        })()
    }, [account, chainId, marketplaceAddress]);

    const handleDataOptionStocks = React.useMemo(() => {
        return dataOptionStocks.length ? dataOptionStocks : [{ value: "ALL", label: "Tất cả" }];
    }, [dataOptionStocks]);

    return (
        <div className="flex flex-col md:flex-row w-full gap-4 justify-between items-end">
            <div className="w-full xl:w-2/3 flex flex-col md:flex-row justify-start gap-4">
                <div className="flex sm:gap-6 gap-4 flex-row w-full">
                    <WrapperSelect title="Loại lệnh">
                        <Select
                            optionLabelProp="label"
                            className="w-full"
                            size="large"
                            defaultValue={ORDER_TYPE.ALL}
                            notFoundContent={<NoData />}
                            onChange={(e) => {
                                const paramsUrl = {
                                    ...storeHistory.paramsUrl,
                                    orderType: e
                                }
                                if (Number(e) === ORDER_TYPE.ALL) {
                                    delete paramsUrl.orderType;
                                }
                                handleGetApiHistory(paramsUrl);
                            }}
                        >
                            {
                                defaultDataOptionStocksCode.map((item: DefaultDataOptionStocksCodeType) => (
                                    <Select.Option
                                        value={item.code}
                                        label={
                                            <div className="flex items-center gap-2">
                                                <IconTrade />
                                                <p className="text-[#777E90] text-sm font-bold">{item.label}</p>
                                            </div>
                                        }
                                    >
                                        <Space>
                                            <p>{item.label}</p>
                                        </Space>
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </WrapperSelect>
                    <WrapperSelect title="Mã cổ phiếu">
                        <Select
                            optionLabelProp="label"
                            className="w-full"
                            size="large"
                            defaultValue="ALL"
                            notFoundContent={<NoData />}
                            onChange={(nftAddress) => {
                                const paramsUrl = {
                                    ...storeHistory.paramsUrl,
                                    stockCode: nftAddress
                                }
                                if (nftAddress === "ALL") {
                                    delete paramsUrl.stockCode;
                                }
                                handleGetApiHistory(paramsUrl);
                            }}
                        >
                            {
                                handleDataOptionStocks.map((item) => (
                                    <Select.Option
                                        value={item.value}
                                        label={
                                            <div className="flex items-center gap-2">
                                                <IconStatusUp />
                                                <p className="text-[#777E90] text-sm font-bold">{item.label}</p>
                                            </div>
                                        }
                                    >
                                        <Space>
                                            <p>{item.label}</p>
                                        </Space>
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </WrapperSelect>
                </div>
                <div className="flex sm:gap-6 gap-4 flex-row w-full">

                    <WrapperSelect title="Từ ngày">
                        <DatePicker
                            inputReadOnly
                            disabledDate={current => current && current.valueOf() > Date.now()}
                            defaultValue={dayjs(dayjs().toISOString())}
                            size="large"
                            allowClear={false}
                            format="DD/MM/YYYY"
                            suffixIcon={<CalendarIcon style={{ padding: '2px', right: '4px' }} />}
                            onChange={date => {
                                const fromDate = Math.floor(dayjs(date).startOf('day').valueOf() / 1000);
                                const paramsUrl = {
                                    ...storeHistory.paramsUrl,
                                    fromDate,
                                }
                                setFormDate(dayjs(date).valueOf());
                                handleGetApiHistory(paramsUrl);
                            }}
                        />
                    </WrapperSelect>
                    <WrapperSelect title="Đến ngày">
                        <DatePicker
                            inputReadOnly
                            disabledDate={current => current && current.valueOf() > Date.now() || current.valueOf() < formDate}
                            defaultValue={dayjs(dayjs().toISOString())}
                            size="large"
                            allowClear={false}
                            format="DD/MM/YYYY"
                            suffixIcon={<CalendarIcon style={{ padding: '2px', right: '4px' }} />}
                            onChange={date => {
                                const toDate = Math.floor(dayjs(date).endOf("day").valueOf() / 1000);
                                const paramsUrl = {
                                    ...storeHistory.paramsUrl,
                                    toDate,
                                }
                                handleGetApiHistory(paramsUrl);
                            }}
                        />
                    </WrapperSelect>
                </div>

            </div>
            <div className="w-full md:w-1/3 flex flex-row justify-end">
                <div className="w-full xl:w-2/3 flex flex-row justify-end">
                    <Input
                        allowClear
                        onChange={(e) => {
                            const paramsUrl = {
                                ...storeHistory.paramsUrl,
                                search: e.target.value
                            }
                            if (!e) {
                                delete paramsUrl.search;
                            }
                            handleGetApiHistory(paramsUrl);
                        }}
                        placeholder="Tìm kiếm mã giao dịch" size="large"
                        suffix={<SearchIcon fill="#777E90" />}
                    />
                </div>
            </div>
        </div>
    )
};
