import * as React from 'react';
import { Pagination } from 'antd';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { PAGE_SIZE_ORDER } from 'config';
import { useActiveWeb3React, useInfoOrder } from 'hooks';
import { useAppDispatch } from 'state';
import { useStateOrder } from 'state/state-info-order';
import { onGetDataOrderId, onLoadingOrder } from 'state/state-info-order/order-slice';
import { GetOrderAsListType } from 'type';

import { HeaderSelect } from './header-select';
import { TableDesktop } from './table-desktop';
import { TableMobile } from './table-mobile';

const ALL = "All";
export const OrderBook = () => {
    useStateOrder();
    const { storeOrder, handleGetApiOrder } = useInfoOrder();
    const dispatch = useAppDispatch();
    const { chainId } = useActiveWeb3React();
    const { data: dataOrder } = storeOrder;

    const [StocksCode, setStocksCode] = React.useState<string>(ALL);
    const [stocksOrderCode, setStocksOrderCode] = React.useState<string>(ALL);
    const [stocksStatus, setStocksStatus] = React.useState<string>(ALL);
    const [itemOffset, setItemOffset] = React.useState<number>(0);
    const [pageCurrent, setPageCurrent] = React.useState<number>(1);

    const filterData = React.useMemo(() => {
        const filterByOrderType = stocksOrderCode.toLowerCase() === ALL.toLowerCase() ? dataOrder : dataOrder.filter((dt) => dt.order_code.toLowerCase() === stocksOrderCode.toLowerCase());
        const filterBySymbol = StocksCode.toLowerCase() === ALL.toLowerCase() ? filterByOrderType : filterByOrderType.filter((dt) => dt.stocks_code.toLowerCase() === StocksCode.toLowerCase());
        const filterByStatusCode = stocksStatus.toLowerCase() === ALL.toLowerCase() ? filterBySymbol : filterBySymbol.filter((dt) => dt.status_code.toLowerCase() === stocksStatus.toLowerCase());
        return filterByStatusCode || [];
    }, [StocksCode, dataOrder, stocksOrderCode, stocksStatus]);

    const totalPagination = filterData.length;
    const dataPagination = [...filterData].splice(itemOffset, PAGE_SIZE_ORDER);

    const handleOnChangeSelectStocksCode = (stocksCode: string) => {
        setStocksCode(stocksCode);
        setPageCurrent(1);
        setItemOffset(0);
        const filterByOrderType = stocksOrderCode?.toLowerCase() === ALL.toLowerCase() ? dataOrder : dataOrder.filter((dt) => dt.order_code.toLowerCase() === stocksOrderCode.toLowerCase());
        const filterBySymbol = stocksCode.toLowerCase() === ALL.toLowerCase() ? filterByOrderType : filterByOrderType.filter((dt) => dt.stocks_code.toLowerCase() === stocksCode.toLowerCase());
        const payload = stocksStatus.toLowerCase() === ALL.toLowerCase() ? filterBySymbol : filterBySymbol.filter((dt) => dt.status_code.toLowerCase() === stocksStatus.toLowerCase());
        const orderId = payload.slice(0, PAGE_SIZE_ORDER).map((item: GetOrderAsListType) => (item.order_id));
        handleGetApiOrder({ orderId, chainId });
    };

    const handleOnChangeSelectStocksOrderCode = (orderCode: string) => {
        setStocksOrderCode(orderCode);
        setPageCurrent(1);
        setItemOffset(0);
        const filterByOrderType = orderCode.toLowerCase() === ALL.toLowerCase() ? dataOrder : dataOrder.filter((dt) => dt.order_code.toLowerCase() === orderCode.toLowerCase());
        const filterBySymbol = StocksCode.toLowerCase() === ALL.toLowerCase() ? filterByOrderType : filterByOrderType.filter((dt) => dt.stocks_code.toLowerCase() === StocksCode.toLowerCase());
        const payload = stocksStatus.toLowerCase() === ALL.toLowerCase() ? filterBySymbol : filterBySymbol.filter((dt) => dt.status_code.toLowerCase() === stocksStatus.toLowerCase());
        const orderId = payload.slice(0, PAGE_SIZE_ORDER).map((item: GetOrderAsListType) => (item.order_id));
        handleGetApiOrder({ orderId, chainId });
    };

    const handleOnChangeSelectStocksStatus = (statusCode: string) => {
        setStocksStatus(statusCode);
        setPageCurrent(1);
        setItemOffset(0);
        const filterByOrderType = stocksOrderCode.toLowerCase() === ALL.toLowerCase() ? dataOrder : dataOrder.filter((dt) => dt.order_code.toLowerCase() === stocksOrderCode.toLowerCase());
        const filterBySymbol = StocksCode.toLowerCase() === ALL.toLowerCase() ? filterByOrderType : filterByOrderType.filter((dt) => dt.stocks_code.toLowerCase() === StocksCode.toLowerCase());
        const payload = statusCode.toLowerCase() === ALL.toLowerCase() ? filterBySymbol : filterBySymbol.filter((dt) => dt.status_code.toLowerCase() === statusCode.toLowerCase());
        const orderId = payload.slice(0, PAGE_SIZE_ORDER).map((item: GetOrderAsListType) => (item.order_id));
        handleGetApiOrder({ orderId, chainId });
    };

    const handleOnChange = (page, size) => {
        const offset = (page - 1) * size;
        setItemOffset(offset);
        setPageCurrent(page);
        const result = [...filterData].splice(offset, PAGE_SIZE_ORDER);
        const orderId = result.map((i) => (i.order_id));
        dispatch(onGetDataOrderId(orderId));
        dispatch(onLoadingOrder(true));
    }

    return (
        <LazyLoadComponent>
            <HeaderSelect
                changeSelectStocksCode={handleOnChangeSelectStocksCode}
                changeSelectStocksStatus={handleOnChangeSelectStocksStatus}
                changeSelectStocksOrderCode={handleOnChangeSelectStocksOrderCode}
            />
            <div className="mt-6 xl:block hidden">
                <TableDesktop data={dataPagination} loading={storeOrder.loading} />
            </div>
            <div className="mt-6 xl:hidden block">
                <TableMobile data={dataPagination} loading={storeOrder.loading} />
            </div>
            {filterData.length > 0
                && <div className="mt-6">
                    <Pagination
                        defaultPageSize={PAGE_SIZE_ORDER}
                        current={pageCurrent}
                        total={totalPagination}
                        onChange={handleOnChange}
                    />
                </div>
            }
        </LazyLoadComponent>
    )
};
