import { TitleTableDesktop } from "components";
import { PAYMENT } from "config";

interface HeaderTransactionsDesktopType {
    title: string;
    children: React.ReactNode
}

export const HeaderTransactionsDesktop = ({ title, children }: HeaderTransactionsDesktopType) => {
    return (
        <>
            <div className="flex justify-between mb-6">
                <h3 className="text-[#23262F] font-bold text-xl">{title}</h3>
                {children}
            </div>
            <div className="grid grid-cols-6">
                <TitleTableDesktop title="Mã cổ phiếu" className="text-xs" classNameWrapper="justify-center" />
                <TitleTableDesktop title="Đơn giá" className="text-xs" classNameWrapper="justify-end items-end px-5" />
                <TitleTableDesktop title="Số lượng" className="text-xs" classNameWrapper="justify-center" />
                <TitleTableDesktop title="Mệnh giá" className="text-xs" classNameWrapper="justify-end items-end px-5" />
                <TitleTableDesktop title="Ngày hết hạn" className="text-xs" classNameWrapper="justify-center w-[150px] relative" />
            </div>
        </>
    )
};
