import * as React from "react";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import _debounce from "lodash/debounce";



import { useStateInfoAsset } from 'state/state-info-asset';
import { useInfoAsset } from 'hooks';

import { InfoAssetType } from 'type';
import { Header } from './header';
import { TableDesktop } from './table-desktop';
import { TableMobile } from './table-mobile';


export const Asset = () => {
    useStateInfoAsset();
    const { storeAsset } = useInfoAsset();
    const [dataAsset, setDataAsset] = React.useState<InfoAssetType[]>([]);

    const onSetDataAsset = _debounce(setDataAsset, 300);

    const handleSearch = React.useCallback((search) => {
        if (!search) setDataAsset(storeAsset.data);
        else {
            const filterNft = storeAsset.data.filter((item) => item.stocks_code.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1);
            onSetDataAsset(filterNft)
        }
    }, [onSetDataAsset, storeAsset.data]);

    React.useEffect(() => {
        if (storeAsset.data) {
            setDataAsset(storeAsset.data);
        }
    }, [storeAsset.data]);

    return (
        <LazyLoadComponent>
            <Header onSearch={handleSearch} />
            <div className="mt-6 xl:block hidden">
                <TableDesktop data={dataAsset} />
            </div>
            <div className="mt-6 xl:hidden block">
                <TableMobile data={dataAsset} loading={storeAsset.loading} />
            </div>
        </LazyLoadComponent>
    )
}
