import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 15.24 22.75 15.49 22.73 15.72C22.7 16.02 22.49 16.28 22.2 16.37C21.91 16.46 21.59 16.36 21.4 16.12C20.69 15.25 19.64 14.75 18.5 14.75C17.65 14.75 16.82 15.04 16.16 15.57C15.26 16.28 14.75 17.34 14.75 18.5C14.75 19.63 15.25 20.69 16.12 21.4C16.36 21.59 16.45 21.91 16.37 22.2C16.28 22.49 16.03 22.7 15.72 22.73C15.49 22.75 15.24 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H14.02C13.52 20.44 13.25 19.49 13.25 18.5C13.25 16.88 13.97 15.39 15.23 14.4C16.91 13.04 19.46 12.92 21.25 14.02V9C21.25 4.39 19.61 2.75 15 2.75H9Z" fill="#292D32" />
            <path d="M21.48 7.85986H2.52002C2.11002 7.85986 1.77002 7.51986 1.77002 7.10986C1.77002 6.69986 2.11002 6.35986 2.52002 6.35986H21.48C21.89 6.35986 22.23 6.69986 22.23 7.10986C22.23 7.51986 21.9 7.85986 21.48 7.85986Z" fill="#292D32" />
            <path d="M8.52002 7.71985C8.11002 7.71985 7.77002 7.37985 7.77002 6.96985V2.10986C7.77002 1.69986 8.11002 1.35986 8.52002 1.35986C8.93002 1.35986 9.27002 1.69986 9.27002 2.10986V6.96985C9.27002 7.37985 8.93002 7.71985 8.52002 7.71985Z" fill="#292D32" />
            <path d="M15.48 7.2699C15.07 7.2699 14.73 6.9299 14.73 6.5199V2.10986C14.73 1.69986 15.07 1.35986 15.48 1.35986C15.89 1.35986 16.23 1.69986 16.23 2.10986V6.5199C16.23 6.9399 15.9 7.2699 15.48 7.2699Z" fill="#292D32" />
            <path d="M18.5 23.75C17.28 23.75 16.1 23.33 15.17 22.56C13.95 21.57 13.25 20.09 13.25 18.5C13.25 16.88 13.97 15.39 15.23 14.4C16.15 13.66 17.31 13.25 18.5 13.25C20.09 13.25 21.57 13.95 22.56 15.18C23.32 16.1 23.75 17.28 23.75 18.5C23.75 19.99 23.1 21.43 21.97 22.44C20.99 23.29 19.76 23.75 18.5 23.75ZM18.5 14.75C17.65 14.75 16.82 15.04 16.16 15.57C15.26 16.28 14.75 17.34 14.75 18.5C14.75 19.63 15.25 20.69 16.12 21.4C17.48 22.53 19.59 22.52 20.98 21.31C21.78 20.59 22.25 19.56 22.25 18.5C22.25 17.63 21.95 16.79 21.4 16.13C20.69 15.25 19.63 14.75 18.5 14.75Z" fill="#292D32" />
            <path d="M17.3798 20.3799C17.1298 20.3799 16.8798 20.2499 16.7398 20.0199C16.5298 19.6599 16.6398 19.1999 16.9998 18.9899L18.0398 18.3699V17.1099C18.0398 16.6999 18.3798 16.3599 18.7898 16.3599C19.1998 16.3599 19.5398 16.6999 19.5398 17.1099V18.7999C19.5398 19.0599 19.3998 19.3099 19.1798 19.4399L17.7698 20.2799C17.6398 20.3399 17.5098 20.3799 17.3798 20.3799Z" fill="#292D32" />
        </Svg>
    );
};

export default Icon;
