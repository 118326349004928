
import { Modal, Select, Space } from 'antd';


import { ExclamationCircleFilled } from '@ant-design/icons';
import { CustomButton, NoData, WrapperSelect } from "components";
import { defaultDataOptionStocksCode } from 'config';
import { useInfoOrder, useTransaction } from 'hooks';
import { IconStatus, IconStatusUp, IconTrade } from 'icons';
import { InfoOrderType } from 'type';

const { confirm } = Modal;
interface HeaderSelectType {
    changeSelectStocksCode: (e: string) => void;
    changeSelectStocksStatus: (e: string) => void;
    changeSelectStocksOrderCode: (e: string) => void;
}

export const HeaderSelect = ({
    changeSelectStocksCode,
    changeSelectStocksStatus,
    changeSelectStocksOrderCode
}: HeaderSelectType) => {

    const { dataOptionStocksCode, dataOptionStocksStatus, storeOrder } = useInfoOrder();
    const { handleCancelAllExpireOrder, loadingTx } = useTransaction();

    const payloadExpireOrder = storeOrder.data.filter((i: InfoOrderType) => i.is_expired).map((item: InfoOrderType) => (item.order_id));

    const showDeleteConfirm = () => {
        confirm({
            mask: true,
            title: 'Bạn có chắc xóa tất cả lệnh hết hạn không?',
            icon: <ExclamationCircleFilled />,
            okText: 'Có',
            okType: 'danger',
            cancelText: 'Không',
            centered: true,
            cancelButtonProps: {
                disabled: loadingTx,
            },
            onOk: async () => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async (resolve, reject) => {
                    const { status } = await handleCancelAllExpireOrder(payloadExpireOrder);
                    if (status === 200) {
                        resolve(status)
                    } else {
                        reject(status)
                    }
                }).catch(() => console.log('Oops errors!'));
            },
        });

    };
    const concatDataOptionStocksCode = [
        { value: "All", label: "Tất cả" },
        ...dataOptionStocksCode
    ]

    return (
        <div className="flex justify-between w-full items-center sm:gap-6 gap-4 sm:flex-row flex-col">
            <div className="flex sm:gap-6 gap-4 sm:flex-row flex-col w-full">
                <div className="flex sm:gap-6 gap-4 flex-row w-full">
                    <WrapperSelect title="Loại lệnh">
                        <Select
                            optionLabelProp="label"
                            className="w-full"
                            defaultValue='All'
                            size="large"
                            onChange={changeSelectStocksOrderCode}
                            notFoundContent={<NoData />}
                        >
                            {
                                defaultDataOptionStocksCode.map((item) => (
                                    <Select.Option
                                        value={item.value}
                                        label={
                                            <div className="flex items-center gap-2">
                                                <IconTrade />
                                                <p className="text-[#777E90] text-sm font-bold">{item.label}</p>
                                            </div>
                                        }
                                    >
                                        <Space>
                                            <p>{item.label}</p>
                                        </Space>
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </WrapperSelect>
                    <WrapperSelect title="Mã cổ phiếu">
                        <Select
                            optionLabelProp="label"
                            className="w-full"
                            defaultValue='All'
                            size="large"
                            onChange={changeSelectStocksCode}
                            notFoundContent={<NoData />}
                        >
                            {
                                concatDataOptionStocksCode.map((item) => (
                                    <Select.Option
                                        value={item.value}
                                        label={
                                            <div className="flex items-center gap-2">
                                                <IconStatusUp />
                                                <p className="text-[#777E90] text-sm font-bold">{item.label}</p>
                                            </div>
                                        }
                                    >
                                        <Space>
                                            <p>{item.label}</p>
                                        </Space>
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </WrapperSelect>
                </div>
                <div className="flex sm:gap-6 gap-4 flex-row justify-between w-full">
                    <WrapperSelect title="Trạng thái">
                        <Select
                            optionLabelProp="label"
                            className="w-full"
                            defaultValue='All'
                            size="large"
                            onChange={changeSelectStocksStatus}
                            notFoundContent={<NoData />}
                        >
                            {
                                dataOptionStocksStatus.map((item) => (
                                    <Select.Option
                                        value={item.value}
                                        label={
                                            <div className="flex items-center gap-2">
                                                <IconStatus />
                                                <p className="text-[#777E90] text-sm font-bold">{item.label}</p>
                                            </div>
                                        }
                                    >
                                        <Space>
                                            <p>{item.label}</p>
                                        </Space>
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </WrapperSelect>
                    {payloadExpireOrder.length > 0 &&
                        <div className="sm:w-32 w-full flex justify-end self-end">
                            <CustomButton
                                disabled={loadingTx}
                                title="Hủy tất cả"
                                className="text-white bg-[#EF1616] w-30"
                                onClick={showDeleteConfirm}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
};
