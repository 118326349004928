import { Table, Tooltip } from "antd";
import clsx from "clsx";

import { CustomButton, NoData, TitleTableDesktop, ValueTableDesktop } from "components";
import { PAYMENT, checkOrderColor, checkStatusColor, formatDate, formatNumberPayment, handleWallet } from "config";
import { useModal } from "hooks/use-modal";
import { InfoOrderType } from "type";

import { useActiveWeb3React, } from "hooks";
import { IconWarningRed, IconWarningYellow } from "icons";
import { TbExternalLink } from "react-icons/tb";
import { getBlockExploreLink } from "utils";

export const TableDesktop = ({ data = [], loading, }: { data: InfoOrderType[], loading: boolean }) => {

    const { toggleModal, ModalTypeEnum } = useModal();
    const { chainId, account } = useActiveWeb3React();


    const columns = [
        {
            title: <TitleTableDesktop title="Loại lệnh" />,
            dataIndex: "order_code",
            key: "order_code",
            render: (_, record: InfoOrderType) => {
                return (
                    <div className="flex items-center gap-4">
                        {record.paused &&
                            <div className="absolute left-[-15px] top-[25px] cursor-pointer">
                                <Tooltip
                                    title={<p
                                        className="text-[#EF1616] text-[13px] font-medium"
                                    >
                                        Mã cổ phiếu đang tạm ngừng giao dịch
                                    </p>
                                    }
                                    placement="topLeft"
                                    color="#FDEDED"
                                >
                                    <IconWarningRed />
                                </Tooltip>
                            </div>
                        }
                        {record.is_expired && !record.paused &&
                            <div className="absolute top-[25px] cursor-pointer left-[-15px]">
                                <Tooltip
                                    title={<p
                                        className="text-[#EF1616] text-[13px] font-medium"
                                    >
                                        Hết thời gian đăng tin, vui lòng hủy để nhận lại tài sản.
                                    </p>
                                    }
                                    placement="topLeft"
                                    color="#FDEDED"
                                >
                                    <IconWarningYellow />
                                </Tooltip>
                            </div>
                        }
                        <ValueTableDesktop
                            color={clsx(
                                record.paused && "text-[#EF1616]",
                                record.is_expired && !record.paused && "text-[#F2B10A]",
                                !record.paused && !record.is_expired && `text-[${checkOrderColor(record.order_type)}]`
                            )}
                            value={record.order_code}
                            className="uppercase text-[13px]"
                        />
                    </div>
                )
            }
        },
        {
            title: <TitleTableDesktop title="Mã giao dịch" classNameWrapper="justify-center" />,
            dataIndex: "transaction",
            key: "transaction",
            render: (_, record: InfoOrderType) => {
                return (
                    <a
                        href={getBlockExploreLink(record.transaction, 'transaction', chainId)}
                        target="_blank" rel="noopener noreferrer"
                        className={clsx("truncate flex gap-1 items-center hover:text-[#355DFF]",
                            record.paused && "text-[#EF1616]",
                            record.is_expired && !record.paused && "text-[#F2B10A]"
                        )}
                    >
                        <ValueTableDesktop
                            color={clsx("hover:text-[#355DFF] text-[#5D5D5B]",
                                record.paused && "text-[#EF1616]",
                                record.is_expired && !record.paused && "text-[#F2B10A]"
                            )}
                            value={handleWallet(record.transaction)}
                            classNameWrapper="justify-center"
                            className="text-[13px]"
                        />
                        <TbExternalLink className={clsx("text-lg hover:text-[#355DFF]",
                            record.paused && "text-[#EF1616]",
                            record.is_expired && !record.paused && "text-[#F2B10A]"
                        )} />
                    </a>
                )
            }
        },
        {
            title: <TitleTableDesktop title="Thời gian" classNameWrapper="justify-center" />,
            dataIndex: "end_time",
            key: "end_time",
            render: (_, record: InfoOrderType) => {
                return (
                    <ValueTableDesktop
                        value={formatDate(record.end_time ? record.end_time : record.create_time)}
                        classNameWrapper="justify-center"
                        color={clsx(record.paused && "text-[#EF1616]", record.is_expired && !record.paused && "text-[#F2B10A]")}
                        className="text-[13px]"
                    />
                )
            }
        },
        {
            title: <TitleTableDesktop title="Mã cổ phiếu" classNameWrapper="justify-center" />,
            dataIndex: "stocks_code",
            key: "stocks_code",
            render: (_, record: InfoOrderType) => {
                return (
                    <ValueTableDesktop
                        value={record.stocks_code.length > 10
                            ? <Tooltip title={record.stocks_code}>{handleWallet(record.stocks_code.toString(), 3)} </Tooltip>
                            : record.stocks_code}
                        classNameWrapper="justify-center"
                        color={clsx(record.paused && "text-[#EF1616]", record.is_expired && !record.paused && "text-[#F2B10A]")}
                        className="text-[13px]"
                    />
                )
            }
        },
        {
            title: <TitleTableDesktop title="Số lượng" classNameWrapper="justify-end" />,
            dataIndex: "original_quantity",
            key: "original_quantity",
            render: (_, record: InfoOrderType) => {
                return (
                    <ValueTableDesktop
                        value={formatNumberPayment(record.original_quantity)}
                        classNameWrapper="justify-end item-end"
                        color={clsx(record.paused && "text-[#EF1616]", record.is_expired && !record.paused && "text-[#F2B10A]")}
                        className="text-[13px]"
                    />
                )
            }
        },
        {
            title: <TitleTableDesktop title="Đơn giá" classNameWrapper="justify-end" />,
            dataIndex: "unit_price",
            key: "unit_price",
            render: (_, record: InfoOrderType) => {
                return (
                    record.unit_price.toString()?.length > 9
                        ? <Tooltip title={formatNumberPayment(record.unit_price)}>
                            <p
                                className={clsx("text-sm font-bold",
                                    record.paused ? "text-[#EF1616]" : "text-[#5D5D5B]",
                                    record.is_expired && !record.paused && "text-[#F2B10A]"
                                )}
                            >
                                {handleWallet(record.unit_price.toString(), 3)}
                            </p>
                        </Tooltip>
                        : <ValueTableDesktop
                            value={formatNumberPayment(record.unit_price)}
                            classNameWrapper="justify-end item-end"
                            color={clsx(record.paused && "text-[#EF1616]", record.is_expired && !record.paused && "text-[#F2B10A]")}
                            className="text-[13px]"
                        />
                )
            }
        },
        {
            title: <TitleTableDesktop title="TGT" titleTooltip={`Tổng giá trị (${PAYMENT})`} classNameWrapper="justify-end" />,
            dataIndex: "total_value",
            key: "total_value",
            render: (_, record: InfoOrderType) => {
                return (
                    record.total_value.toString()?.length > 9
                        ? <Tooltip title={formatNumberPayment(record.total_value)}>
                            <p className={clsx(" text-sm font-bold",
                                record.paused ? "text-[#EF1616]" : "text-[#5D5D5B]",
                                record.is_expired && !record.paused && "text-[#F2B10A]"
                            )}>
                                {handleWallet(record.total_value.toString(), 3)}
                            </p>
                        </Tooltip>
                        : <ValueTableDesktop
                            value={formatNumberPayment(record.total_value)}
                            classNameWrapper="justify-end item-end"
                            color={clsx(record.paused && "text-[#EF1616]", record.is_expired && !record.paused && "text-[#F2B10A]")}
                            className="text-[13px]"
                        />
                )
            }
        },
        {
            title: <TitleTableDesktop title="ĐGD" titleTooltip="Số lượng đã giao dịch" classNameWrapper="justify-end" />,
            dataIndex: "transaction_quantity",
            key: "transaction_quantity",
            render: (_, record: InfoOrderType) => {
                return (
                    <ValueTableDesktop
                        value={formatNumberPayment(record.transaction_quantity)}
                        classNameWrapper="justify-end"
                        color={clsx(record.paused && "text-[#EF1616]", record.is_expired && !record.paused && "text-[#F2B10A]")}
                        className="text-[13px]"
                    />
                )
            }
        },
        {
            title: <TitleTableDesktop title="CL" titleTooltip="Số lượng còn lại" classNameWrapper="justify-end" />,
            dataIndex: "remaining_quantity",
            key: "remaining_quantity",
            render: (_, record: InfoOrderType) => {
                return (
                    <ValueTableDesktop
                        value={formatNumberPayment(record.remaining_quantity)}
                        classNameWrapper="justify-end"
                        color={clsx(record.paused && "text-[#EF1616]", record.is_expired && !record.paused && "text-[#F2B10A]")}
                        className="text-[13px]"
                    />
                )
            }
        },
        {
            title: <TitleTableDesktop title="ĐH" titleTooltip="Số lượng đã hủy" classNameWrapper="justify-end" />,
            dataIndex: "cancel_quantity",
            key: "cancel_quantity",
            render: (_, record: InfoOrderType) => {
                return (
                    <ValueTableDesktop
                        value={formatNumberPayment(record.cancel_quantity)}
                        classNameWrapper="justify-end"
                        color={clsx(record.paused && "text-[#EF1616]", record.is_expired && !record.paused && "text-[#F2B10A]")}
                        className="text-[13px]"
                    />
                )
            }
        },
        {
            title: <TitleTableDesktop title="Trạng thái" classNameWrapper="justify-center" />,
            dataIndex: "status_code",
            key: "status_code",
            render: (_, record: InfoOrderType) => {
                return (
                    <div className="flex justify-end">
                        <p
                            className={clsx("font-bold text-[13px]",
                                record.is_expired && !record.paused ? "text-[#F2B10A]" : record.paused ? "text-[#EF1616]" : `text-[${checkStatusColor(record.status)}]`
                            )}
                        >{record.status_code}
                        </p>
                    </div>
                )
            }
        },
        {
            title: <TitleTableDesktop title="Hành động" classNameWrapper="justify-center" />,
            dataIndex: "act",
            key: "act",
            render: (_, record: InfoOrderType) => {
                return (
                    <div className="flex justify-center">
                        <CustomButton
                            disabled={record.paused}
                            title="Hủy"
                            className="text-white bg-[#EF1616] text-[13px]"
                            onClick={() => toggleModal({
                                type: ModalTypeEnum.MODAL_CANCEL_ORDER_BOOK,
                                title: "Hủy",
                                data: {
                                    ...record
                                }
                            })}
                        />
                    </div>
                )
            }
        },
    ];


    return (
        <Table
            loading={account ? loading : false}
            locale={{
                emptyText: <NoData />,
            }}
            dataSource={data}
            columns={columns}
            pagination={false}
        />
    )
};


