import { Button } from '@pancakeswap/uikit';

import { useModal, usePagination } from 'hooks';

import { LoadingTransactionMobile, NoData, TitleTableMobile, ValueTableMobile, WrapperCollapse } from 'components'

import { PAYMENT, formatDate, formatNumberPayment } from 'config';
import { TransactionType } from 'type';
import { ButtonSellOrder } from './button-sell-order';

const defaultPageSize = 5;
export const BuySharesMobile = ({ data, loading }: { data: TransactionType[], loading: boolean }) => {
    const { toggleModal, ModalTypeEnum } = useModal();
    const { pagination, dataPagination } = usePagination({ data, pageSize: defaultPageSize });

    if (loading) {
        return (
            <div>
                <LoadingTransactionMobile />
            </div>
        )
    }

    return (
        <div>
            <div className="flex justify-end">
                <ButtonSellOrder className="w-36 rounded" />
            </div>
            {
                dataPagination.length > 0 ? <>
                    <div className="mt-6 grid gap-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1">
                        {
                            dataPagination.map((item: TransactionType, index) => (
                                <div
                                    key={`${index.toString()}`}
                                    className="rounded-lg py-3 px-4"
                                    style={{ border: "1px solid #E1E8E5" }}
                                >
                                    <div className="grid-cols-3 grid pb-3"  >
                                        <div className="flex flex-col gap-3">
                                            <TitleTableMobile title="Mã cổ phiếu" />
                                            <ValueTableMobile value={item.stocks_code} />
                                        </div>
                                        <div className="flex flex-col gap-3 items-center">
                                            <TitleTableMobile title="Đơn giá" />
                                            <ValueTableMobile value={formatNumberPayment(item.unit_price)} />
                                        </div>
                                        <div className="flex flex-col gap-3 items-end">
                                            <TitleTableMobile title="Số lượng" />
                                            <ValueTableMobile value={formatNumberPayment(item.quantity)} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex justify-between">
                                            <div className="flex items-center gap-1">
                                                <TitleTableMobile title="Mệnh giá" />
                                                <p className="text-[#23262F] text-sm">:</p>
                                                <ValueTableMobile value={formatNumberPayment(item.price)} className="font-normal" />
                                            </div>
                                            <Button
                                                className="rounded px-4"
                                                scale="sm"
                                                variant="success"
                                                onClick={() => toggleModal({
                                                    type: ModalTypeEnum.MODAL_BUY_SHARES,
                                                    title: "MUA CỔ PHIẾU",
                                                    data: {
                                                        ...item
                                                    }
                                                })}
                                            >Mua</Button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="mt-6">
                        {pagination()}
                    </div>
                </>
                    : <NoData />
            }
        </div>
    )
}
