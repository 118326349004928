import * as React from 'react';
import { Pagination, Tooltip } from 'antd';
import clsx from 'clsx';

import { CustomButton, LoadingTransactionMobile, NoData, TitleTableMobile, ValueTableMobile, WrapperCollapse } from 'components';
import { PAYMENT, checkOrderColor, checkStatusColor, formatDate, formatNumberPayment, handleWallet } from 'config';
import { useModal } from 'hooks/use-modal';

import { useActiveWeb3React } from 'hooks';
import { TbExternalLink } from "react-icons/tb";
import { InfoOrderType } from 'type';
import { getBlockExploreLink } from 'utils';

export const TableMobile = ({ data = [], loading }: { data: InfoOrderType[], loading: boolean }) => {
    const { toggleModal, ModalTypeEnum } = useModal();
    const { chainId } = useActiveWeb3React();

    if (loading) {
        return <LoadingTransactionMobile />
    }
    if (!data.length) {
        return (
            <div>
                <NoData />
            </div>
        )
    }
    return (
        <div>
            <div className="lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 grid gap-4">
                {
                    data.map((item: InfoOrderType, index) => (
                        <div
                            className="rounded-lg pb-4"
                            style={{ borderBottom: "1px solid #E1E8E5" }}
                            key={`${index.toString()}`}
                        >
                            <div className="flex flex-row justify-between pb-3">
                                <div className="flex flex-col gap-y-[14px] w-1/3">
                                    <TitleTableMobile title="Loại lệnh" />
                                    <ValueTableMobile
                                        value={item.order_code}
                                        color={item.paused ? "text-[#EF1616]" : `text-[${checkOrderColor(item.order_type)}]`} />
                                </div>
                                <div className="flex flex-col gap-y-[14px] w-1/6">
                                    <TitleTableMobile title="Mã" classNameWrapper="justify-start" />
                                    <ValueTableMobile
                                        value={item?.stocks_code?.length > 10
                                            ? <Tooltip title={item.stocks_code}>{handleWallet(item.stocks_code.toString(), 2)}</Tooltip>
                                            : item.stocks_code}
                                        color="text-[#000000]" classNameWrapper="justify-start" />
                                </div>
                                <div className="flex flex-col gap-y-[14px] w-1/6">
                                    <TitleTableMobile title="TGT" titleTooltip={`Tổng giá trị (${PAYMENT})`} classNameWrapper="justify-start" />
                                    <ValueTableMobile value={formatNumberPayment(item.total_value)} color="text-[#000000]" classNameWrapper="justify-start" />
                                </div>
                                <div className="flex flex-col gap-y-[14px] w-1/3">
                                    <TitleTableMobile title="Trạng thái" classNameWrapper="justify-end" />
                                    <p className={clsx("font-bold text-sm flex justify-end", item.paused ? "text-[#EF1616]" : `text-[${checkStatusColor(item.status)}]`)}>{item.status_code}</p>
                                </div>
                            </div>
                            <WrapperCollapse>
                                <div className="flex flex-col gap-2 mt-2">
                                    <div className="flex justify-between">
                                        <TitleTableMobile title="Mã giao dịch" />
                                        <a
                                            href={getBlockExploreLink(item.transaction, 'transaction', chainId)}
                                            target="_blank" rel="noopener noreferrer"
                                            className="truncate flex gap-1 items-center hover:text-[#355DFF]"
                                        >
                                            <ValueTableMobile
                                                value={handleWallet(item.transaction)}
                                                classNameWrapper="justify-center"
                                                color={item.paused ? "text-[#EF1616]" : "hover:text-[#355DFF] text-[#23262F]"}
                                            />
                                            <TbExternalLink className={item.paused ? "text-[#EF1616]" : "text-xl hover:text-[#355DFF]"} />
                                        </a>
                                    </div>
                                    <div className="flex justify-between">
                                        <TitleTableMobile title="Thời gian" />
                                        <ValueTableMobile value={formatDate(item.end_time ? item.end_time : item.create_time)} className="font-normal" color={item.paused && "text-[#EF1616]"} />
                                    </div>
                                    <div className="flex justify-between">
                                        <TitleTableMobile title="Số lượng" />
                                        <ValueTableMobile value={formatNumberPayment(item.original_quantity)} className="font-normal" color={item.paused && "text-[#EF1616]"} />
                                    </div>
                                    <div className="flex justify-between">
                                        <TitleTableMobile title={`Đơn giá (${PAYMENT})`} />
                                        <ValueTableMobile value={formatNumberPayment(item.unit_price)} className="font-normal" color={item.paused && "text-[#EF1616]"} />
                                    </div>
                                    <div className="flex justify-between">
                                        <TitleTableMobile title="Số lượng đã giao dịch" />
                                        <ValueTableMobile value={formatNumberPayment(item.transaction_quantity)} className="font-normal" color={item.paused && "text-[#EF1616]"} />
                                    </div>
                                    <div className="flex justify-between">
                                        <TitleTableMobile title="Số lượng đã hủy" />
                                        <ValueTableMobile value={formatNumberPayment(item.cancel_quantity)} className="font-normal" color={item.paused && "text-[#EF1616]"} />
                                    </div>
                                    <div className="flex justify-between">
                                        <TitleTableMobile title="Số lượng còn lại" />
                                        <ValueTableMobile value={formatNumberPayment(item.remaining_quantity)} className="font-normal" color={item.paused && "text-[#EF1616]"} />
                                    </div>
                                    <CustomButton
                                        disabled={item.paused}
                                        title="Hủy"
                                        className="text-white bg-[#EF1616] w-full mt-3"
                                        onClick={() => toggleModal({
                                            type: ModalTypeEnum.MODAL_CANCEL_ORDER_BOOK,
                                            title: "Hủy",
                                            data: {
                                                ...item
                                            }
                                        })}
                                    />
                                </div>
                            </WrapperCollapse>
                            {item.paused && <p className="text-[#EF1616] text-xs font-semibold text-center mt-4">
                                Mã cổ phiếu đang tạm ngừng giao dịch
                            </p>}
                            {item.is_expired && !item.paused && <p className="text-[#F2B10A] text-xs font-semibold text-center mt-4">
                                Hết thời gian đăng tin, vui lòng hủy để nhận lại tài sản.
                            </p>}
                        </div>
                    ))
                }
            </div>

        </div>
    )
};
