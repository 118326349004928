import { Tooltip } from "antd";
import { Button } from "@pancakeswap/uikit";

import { useModal, usePagination } from "hooks";
import { formatDate, formatNumberPayment, handleWallet } from "config";
import { LoadingTransactionDesktop, NoData, ValueTableDesktop } from "components";
import { TransactionType } from "type";
import { ButtonBuyOrder } from "./button-buy-order";
import { HeaderTransactionsDesktop } from "../header-transactions-desktop";

const defaultPageSize = 5;
export const SellSharesDesktop = ({ data, loading }: { data: TransactionType[], loading: boolean }) => {
    const { toggleModal, ModalTypeEnum } = useModal();
    const { pagination, dataPagination } = usePagination({ data, pageSize: defaultPageSize });

    if (loading) {
        return (
            <div>
                <HeaderTransactionsDesktop title="Bán cổ phiếu">
                    <ButtonBuyOrder className="w-36 rounded-md" />
                </HeaderTransactionsDesktop>
                <LoadingTransactionDesktop className="mt-6" />
            </div>
        )
    }
    return (
        <div>
            <HeaderTransactionsDesktop title="Bán cổ phiếu">
                <ButtonBuyOrder className="w-36 rounded-md" />
            </HeaderTransactionsDesktop>
            {
                dataPagination.length > 0 ? dataPagination.map((item: TransactionType) => (
                    <div
                        style={{ border: "1px solid rgba(207, 219, 213, 0.60)" }}
                        className="grid grid-cols-6 rounded-md mt-5"
                    >
                        <ValueTableDesktop
                            value={
                                item?.stocks_code?.length > 10
                                    ? <Tooltip title={item.stocks_code}>{handleWallet(item.stocks_code.toString(), 3)} </Tooltip>
                                    : item.stocks_code
                            }
                            className="font-normal"
                            classNameWrapper="items-center p-5"
                        />
                        <ValueTableDesktop
                            value={
                                item.unit_price.toString().length > 10
                                    ? <Tooltip title={formatNumberPayment(item.unit_price)}>{handleWallet(item.unit_price.toString(), 3)} </Tooltip>
                                    : formatNumberPayment(item.unit_price)
                            }
                            className="font-normal"
                            classNameWrapper="items-end justify-end p-5"
                        />
                        <ValueTableDesktop
                            value={item.quantity.toString().length > 10
                                ? <Tooltip title={formatNumberPayment(item.quantity)}>{handleWallet(item.quantity.toString(), 3)} </Tooltip>
                                : formatNumberPayment(item.quantity)}
                            className="font-normal"
                            classNameWrapper="items-end justify-end p-5"
                        />
                        <ValueTableDesktop
                            value={
                                item.price.toString().length > 10
                                    ? <Tooltip title={formatNumberPayment(item.price)}>{handleWallet(item.price.toString(), 3)} </Tooltip>
                                    : formatNumberPayment(item.price)
                            }
                            className="font-normal"
                            classNameWrapper="items-end justify-end p-5"
                        />
                        <ValueTableDesktop value={formatDate(item.end_time)} className="font-normal" classNameWrapper="items-center justify-center w-[150px] relative xl:right-[15px] right-[30px]" />
                        <div className="flex items-center justify-center relative left-[15px]">
                            <Button
                                scale="sm"
                                className="rounded-md w-14 text-xs"
                                variant="red"
                                onClick={() => toggleModal({
                                    type: ModalTypeEnum.MODAL_SELL_SHARES,
                                    title: "BÁN CỔ PHIẾU",
                                    data: {
                                        ...item
                                    }
                                })}
                            >Bán</Button>
                        </div>
                    </div>
                )) : <NoData className="mt-6" />
            }
            {
                data.length > defaultPageSize && <div className="mt-5">
                    {pagination()}
                </div>
            }
        </div>
    )
};
