import { NoData, TitleTableMobile, ValueTableMobile } from 'components'
import { PAYMENT, formatNumberPayment } from 'config'
import { usePagination } from 'hooks/use-pagination';
import { InfoAssetType } from 'type';

export const TableMobile = ({ data, loading }: { data: InfoAssetType[], loading: boolean }) => {

    const { dataPagination, pagination } = usePagination({ data, pageSize: 5 });
    if (loading) {
        return (
            <div
                className="animate-pulse p-4 rounded-md grid gap-4"
                style={{
                    border: "1px solid rgba(207, 219, 213, 0.60)"
                }}
            >
                <div className="flex w-full justify-between">
                    <div className="h-4 w-20 bg-slate-300 rounded" />
                    <div className="h-4 w-28 bg-slate-300 rounded" />
                </div>
                <div className="flex w-full justify-between">
                    <div className="h-4 w-24 bg-slate-300 rounded" />
                    <div className="h-4 w-32 bg-slate-300 rounded" />
                </div>
                <div className="flex w-full justify-between">
                    <div className="h-4 w-16 bg-slate-300 rounded" />
                    <div className="h-4 w-36 bg-slate-300 rounded" />
                </div>
            </div>
        )
    }

    if (!dataPagination.length) {
        return (
            <div>
                <NoData />
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-3">
            {
                dataPagination.map((item: InfoAssetType) => (
                    <div
                        className="rounded-lg p-3 px-6"
                        style={{ border: item.paused ? "2px solid #EF1616" : "2px solid #E1E8E5" }}
                        key={item.stocks_code}
                    >
                        <div className="flex flex-col">
                            <div className="flex flex-row w-full justify-between py-[7px]">
                                <TitleTableMobile title="Mã cổ phiếu" />
                                <ValueTableMobile value={item.stocks_code} />
                            </div>
                            <div className="flex flex-row w-full justify-between py-[7px]">
                                <TitleTableMobile title="Số lượng" />
                                <ValueTableMobile value={formatNumberPayment(item.quantity)} />
                            </div>
                            <div className="flex flex-row w-full justify-between py-[7px]">
                                <TitleTableMobile title={`Mệnh giá (${PAYMENT})`} />
                                <ValueTableMobile value={formatNumberPayment(item.price)} />
                            </div>
                            <div className="flex flex-row w-full justify-between py-[7px]">
                                <TitleTableMobile title={`Tổng giá trị (${PAYMENT})`} />
                                <ValueTableMobile value={formatNumberPayment(item.total_value)} />
                            </div>
                        </div>
                        {item.paused && <p className="text-[#EF1616] text-xs font-semibold text-center mt-4">
                            Mã cổ phiếu đang tạm ngừng giao dịch
                        </p>}
                    </div>
                ))
            }
            {
                dataPagination.length > 0 &&
                <div className="mt-4 text-center">
                    {pagination()}
                </div>
            }
        </div>
    )
}
